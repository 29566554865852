import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/main.scss'
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter, 
  RouterProvider
} from 'react-router-dom'; 
import { MotionConfig } from 'framer-motion';
import netlifyIdentity from 'netlify-identity-widget';

//Pages
import Home from './pages/home/Home';
import Mission from './pages/mission/Mission';
import Contact from './pages/contact/Contact';
import Layout from './layout/Layout';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/events';
import Story from './pages/story/Story';
import Social from './pages/social/Social';
import Admin from './admin/Admin';

const MainView = () => {

  useEffect(() => {
    netlifyIdentity.init({
      APIUrl: 'https://healingwatersforwarriors.com/.netlify/identity',
    });
    if(window.location.hash.includes("invite_token")) {
      netlifyIdentity.open(); 
    }

    // Register event listeners for login/signup
    netlifyIdentity.on('login', user => {
      console.log('Login successful:', user);
      netlifyIdentity.close(); // Close the widget after login/signup
    });

    netlifyIdentity.on('logout', () => {
        console.log('Logged out');
    });

    return () => {
      netlifyIdentity.off('login');
      netlifyIdentity.off('logout');
    }

  }, [])

  return(
    <MotionConfig
  >

    <section id="home">
      <Home />
    </section>
    
    <section id="mission">
      <Mission />
    </section>
    <section id="story">
      <Story />
    </section>
    <section id="gallery">
      <Gallery /> 
    </section> 
    <section id="contact">
      <Contact /> 
    </section>
  </MotionConfig>
  )
}
  


const router = createBrowserRouter([
  {
    element: <Layout />, 
    children: [
      {
        index: true, 
        path: "/", 
        element: <MainView />
      },
      {
        path: "/events",
        element: <Events />
      },
      {
        path: "/social", 
        element: <Social />
      }
      
    ]
  }, 
  {
    path: "/admin", 
    element: <Admin />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
