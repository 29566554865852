import React, { useEffect, useState } from 'react'; 
import SectionHeader from '../../components/SectionHeader';
import Image1 from '../../assets/image_2.jpg'
import Image2 from '../../assets/story_image_2.jpg'
import Image3 from '../../assets/story_image_3.jpg'
import { motion } from 'framer-motion';


const get_mission_images = () => {

    try {
        const context = require.context('../../../content/mission', true, /\.json$/);
        const keys = context.keys(); 

        let image_paths = []; 

        keys.forEach((key) => {
            const image = context(key); 
            image_paths.push(image.image); 
        })

        console.log(image_paths);
        return image_paths; 
    }
    catch(err) {
        console.log(err); 
        return []; 
    }
    
}

const Story = () => {

    const [images, setImages] = useState([]); 

    useEffect(() => {
        const _images = get_mission_images('mission'); 
        setImages(_images); 
        console.log(images); 
    }, []); 

    return(
        <div>
            <SectionHeader HeaderTitle={"The mission of healing waters"} />
            

            <div style={{
                marginLeft: "10%", 
                marginRight: "10%", 
                marginTop: "2rem",
                display: 'flex', 
                flexDirection: 'column',
                fontSize: "1.2rem"
            }}>
                
                <motion.div
                    className="section-body-wrapped"
                    initial={{ opacity: 0, y: -40 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{duration: 1.5, delay: 0.5}}
                    viewport={{}}
                >   
                    <p>
                    At Healing Waters for Warriors, our vision is to be a beacon of hope
and restoration for active service members, veterans and first responders. We
envision a future where every individual who has sacrificed for our country finds a
nurturing environment that fosters both education and support. Through
engaging activities and unwavering assistance, we aspire to facilitate the journey
of healing and successful reintegration into society and family life. Our vision is
rooted in the belief that every warrior deserves the opportunity to thrive,
and we are steadfast in our commitment to being a guiding light on their path to
renewed purpose and fulfillment.
                    </p>
                </motion.div>
                <motion.div className="story-images"
                    initial={{ opacity: 0, y: 70 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{duration: 1.5, delay: 0.75}}
                    viewport={{}}
                >
                    {
                        images.map((image, index) => (
                            <img
                                src={image}
                            />
                        ))
                    }

                </motion.div>
                
            </div>
        </div>
    )
}

export default Story; 