import React, { useRef, useState } from 'react'; 
import SectionHeader from '../../components/SectionHeader';
import { Form } from 'react-bootstrap';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser'; 
import ReCAPTCHA from 'react-google-recaptcha';
import { TailSpin } from 'react-loading-icons';
const STATES = [ 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];

const Contact = () => {

    const [submitted, setSubmitted] = useState(false); 
    const [submitting, setSubmitting] = useState(false); 

    const form = useRef(); 

    const sendMail = (e) => {
        setSubmitting(true); 
        e.preventDefault(); 
        console.log(form.current); 
        emailjs.sendForm(
            "service_ytyta86", 
            "template_rlatsro",
            form.current, 
            {publicKey: "qbZ2vwPLVCTi27nfJ"}
        )
        .then((result) => {
            setSubmitting(false); 
            setSubmitted(true);
            console.log(result)
        })
        .catch((error) => {
            setSubmitted(false); 
            setSubmitting(false); 
            console.error(error); 
        })
    }

    return(
        <div>
            <SectionHeader HeaderTitle={"Contact us"}/>
            <motion.div className="section-body" style={{
                marginTop: "100px"
            }}
                initial={{opacity: 0}}
                whileInView={{opacity: 1}}
                transition={{duration: 1.5, delay: 0.5}}
            >
                <Form
                    style={{
                        width: "50%",
                        margin: 'auto'
                    }}
                    onSubmit={(e) => e.preventDefault()}
                    ref={form}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >   
                        <Form.Group className="mb-3" controlId={'first_name'}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" name="first_name"/>
                        </Form.Group> 
                        <Form.Group className="mb-3" controlId={'last_name'}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" name="last_name"/>
                        </Form.Group> 

                    </div>
                    <Form.Group className="mb-3" controlId={'email_address'}>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="text" name="email_address"/>
                    </Form.Group> 
                    <Form.Group className="mb-3" controlId={'phone_number'}>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" name="phone_number"/>
                    </Form.Group> 
                    <Form.Group style={{width: "50%"}} controlId={'location'} className="mb-3">
                        <Form.Label>Location</Form.Label>
                        <Form.Select name="location">
                            {
                                STATES.map((val, i) => {
                                    return(
                                        <option value={val}>{val}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contact_reason">
                        <Form.Label>Reason for Contact</Form.Label>
                        <Form.Control as="textarea" rows={3} name="contact_reason"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <ReCAPTCHA 
                            sitekey="6Ldk4iwqAAAAAFn7GC71oA_mr8Skj96bnffd_W4v"
                            name="g-recaptcha-response"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 form-submit">
                        {
                            submitted ? <p>Thank you for reaching out, you will hear back from us soon!</p> : <button onClick={sendMail} disabled={submitting}>{submitting ? (<TailSpin stroke="blue"/>) : "Submit"}</button>
                        }
                    </Form.Group>
                </Form>
            </motion.div>
        </div>
    )
}

export default Contact; 