import React, { useEffect, useState } from 'react'; 
import SectionHeader from '../../components/SectionHeader';
import Image1 from '../../assets/image_1.jpg'
import UniformPic from '../../assets/uniform.jpg'
import { motion } from 'framer-motion';


const get_story_image = () => {
    try{
        const context = require.context('../../../content/story', true, /\.json$/);
        const keys = context.keys(); 

        let image_paths = []; 

        keys.forEach((key) => {
            const image = context(key); 
            image_paths.push(image.image); 
        })

        return image_paths; 
    }
    catch(err) {
        console.log(err); 
        return []; 
    }
}

const Story = () => {
    const [image, setImage] = useState(null); 

    useEffect(() => {
        const _image = get_story_image(); 
        setImage(_image); 
    }, []); 

    return(
        <div>
            <SectionHeader HeaderTitle={"My Story"} />
            <div 
                className="section-body"
                style={{
                    display: 'flex', 

                }}
            >
                <motion.div 
                    style={{flex: 1}}
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{duration: 1.5, delay: 0.25}}
                    viewport={{}}
                >
                    <img 
                        src={image}
                        style={{
                            maxHeight: '390px',
                            marginTop: "2rem"
                        }}
                    />
                </motion.div>
                <motion.div 
                    style={{
                        flex: 1,
                        marginTop: "auto", 
                        marginBottom: "auto",
                        textAlign: 'left',
                        fontSize: "1.2rem"
                    }}
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{duration: 1.5, delay: 0.5}}
                    viewport={{}}
                >   
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Lieutenant Colonel Larry Boggs, retired officer of the West Virginia National
                        Guard, is the founder and President of Healing Waters for Warriors. Larry is a
                        native West Virginian who served four years in the United States Marines
                        whereupon he joined and served in the West Virginia Army National Guard for
                        another 26 years. He has served in a variety of command, leadership, and
                        managerial positions as an Armor officer and 35th Civil Support Team Battalion
                        Commander, including combat tour during Operation Iraqi Freedom II.
                    </p>
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;Upon retirement he knew he wanted to give back to those who he had once
                        served alongside. Founding Healing Waters for Warriors. In 2022 was the
                        obvious next step to continue to serve by providing a pathway of fellowship and
                        recovery for service members.
                    </p>
                </motion.div>
                    
                
            </div>
        </div>
    )
}


export default Story; 
